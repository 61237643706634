import Link from 'next/link'
import { useRouter } from 'next/router'

import LogoIcon from '@/components/icons/Logo'

export default function NotFound(): JSX.Element {
	const router = useRouter()

	return (
		<div className="w-full min-h-screen bg-green-400 flex items-center justify-center px-6">
			<div className="bg-white rounded-lg shadow w-full flex flex-col items-center justify-center py-6 px-4 max-w-2xl sm:px-8 md:rounded-2xl lg:max-w-3xl lg:p-8 xl:rounded-3xl xl:max-w-4xl">
				<LogoIcon className="w-32" />

				<p className="text-6xl font-semibold mt-6 sm:text-9xl sm:mt-10 lg:mt-16">404</p>

				<div className="mt-4 text-center sm:mt-6 lg:mt-12">
					<p className="text-lg font-bold lg:text-3xl">Oops!</p>
					<p className="lg:text-lg lg:mt-3">The page you were looking for can’t be found.</p>
				</div>

				<div className="mt-6 w-full sm:flex sm:justify-center sm:items-end lg:mt-16">
					<Link href="/">
						<a className="w-full text-center inline-block py-1 text-xs bg-gradient-to-b from-green-400 via-green-500 to-green-500 text-white rounded-full uppercase font-extrabold shadow-md leading-5 sm:text-base sm:py-2 lg:text-xl focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-400">Back To Home</a>
					</Link>

					<button onClick={() => router.back()} className="w-full mt-2 py-1 text-xs bg-gradient-to-b text-green-400 rounded-full border border-gray-200 uppercase font-extrabold shadow-md leading-5 sm:ml-4 sm:text-base sm:py-2 lg:text-xl lg:ml-8 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-400">Previous Page</button>
				</div>

				<p className="text-center mt-6 lg:mt-16 lg:text-xl">To contact one of our sales representatives call: <a href="tel:+18004447223" className="text-green-400 font-extrabold block sm:inline">1-800-444-7223</a></p>
			</div>
		</div>
	)
}
